import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import LeftArrowWhite from "../../assets/images/left-arrow-white.png";
import RightArrowWhite from "../../assets/images/right-arrow-white.png";
import "./stepthree.scss";
import { states } from "./StepThreeData";
import "./t_responsive.scss";

const StepThree = (props) => {
  const { formData, setFormData: setData, nextStep, pevStep } = props;
  const [dl_states, setDlState] = useState([]);
  const inputRef = useRef();
  const [address, setAddress] = useState(formData.address);
  const [city, setCity] = useState(formData.city);
  const [state, setState] = useState(formData.state);
  const [zip_code, setzipCode] = useState(formData.zip_code);
  const autocomplete = useRef();

  useEffect(() => {
    setDlState(states);
  }, [dl_states]);

  useEffect(() => {
    const scripts = document.createElement("script");
    scripts.async = true;

    scripts.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDy8Da41CikSjf1KhrA2gSauRM-GGF6doo&callback=initAutocomplete&libraries=places&v=weekly";

    const body = document.querySelector("body");

    body.appendChild(scripts);
    window.initAutocomplete = initAutocomplete;
  }, []);

  const initAutocomplete = () => {
    autocomplete.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      }
    );

    autocomplete.current.addListener("place_changed", () => {
      const place = autocomplete.current.getPlace();
      setAddress(
        inputRef.current.value ? inputRef.current.value : formData.location
      );
      localStorage.setItem("address", inputRef.current.value);

      for (const components of place.address_components) {
        const componenttype = components.types[0];
        if (componenttype == "postal_code") {
          setzipCode(components.long_name);
        } else if (componenttype == "administrative_area_level_1") {
          setState(components.long_name);
        } else if (componenttype == "locality") {
          setCity(components.long_name);
        } else {
        }
      }
    });
  };

  const validationSchema = Yup.object({
    last_4_ssn_ein: Yup.string()
      .length(4, "Must be 4 character")
      .required("Last 4 ssn ein field required"),
    dl_state: Yup.string().required("Dl state field is required!"),
    dl_number: Yup.string().required("Dl Number field is required!"),
  });

  const handleSubmit = (values) => {
    setData(values);
    setData({
      ...values,
      address: address,
      city: city,
      zip_code: zip_code,
      state: state,
    });
    nextStep();
  };

  return (
    <>
      <div id="stepper3" className="steppers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="step" id="stepper-three">
                <div className="header">
                  <div className="title">Step 03</div>
                  <div className="subtitle">Application Details</div>
                </div>
                <div className="body">
                  <div className="form">
                    <div className="">
                      <Formik
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        <Form id="app_detail" name="app_detail">
                          <div className="form-group">
                            <label>Company Name</label>
                            <Field
                              name="company_name"
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  name="address"
                                  ref={inputRef}
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>City</label>

                                <Field
                                  name="city"
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  value={city}
                                  onChange={(e) => {
                                    setCity(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>State</label>
                                <Field
                                  name="state"
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  value={state}
                                  onChange={(e) => {
                                    setState(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>Zip Code</label>
                                <Field
                                  name="zip_code"
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  value={zip_code}
                                  onChange={(e) => {
                                    setzipCode(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>Driving licensee State</label>
                                <Field
                                  name="dl_state"
                                  className="form-control thrd-step"
                                  as="select"
                                >
                                  <option selected value="">
                                    Nothing selected
                                  </option>
                                  {dl_states.map((state, index) => (
                                    <option
                                      key={index}
                                      value={state.abbreviation}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </Field>
                                <div className="error" style={{ color: "red" }}>
                                  <ErrorMessage name="dl_state" />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>Driving licensee Number</label>
                                <Field
                                  name="dl_number"
                                  type="text"
                                  className="form-control thrd-step"
                                  id="exampleFormControlInput1"
                                />
                                <div className="error" style={{ color: "red" }}>
                                  <ErrorMessage name="dl_number" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label>Last 4 of SSN or EIN</label>
                                <Field
                                  name="last_4_ssn_ein"
                                  type="text"
                                  className="form-control thrd-step"
                                  id="exampleFormControlInput1"
                                />
                                <div className="error" style={{ color: "red" }}>
                                  <ErrorMessage name="last_4_ssn_ein" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="continue">
                            <button
                              type="button"
                              onClick={pevStep}
                              className="accept-continue"
                            >
                              <img
                                className="left-arrow"
                                src={LeftArrowWhite}
                              />
                              Back
                            </button>
                            <button type="submit" className="accept-continue">
                              Next
                              <img
                                className="right-arrow"
                                src={RightArrowWhite}
                              />
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;
