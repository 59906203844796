import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import StepFive from "../../components/StepFive/StepFive";
import StepFour from "../../components/StepFour/StepFour";
import StepOne from "../../components/StepOne/StepOne";
import StepThree from "../../components/StepThree/StepThree";
import StepTwo from "../../components/StepTwo/StepTwo";

const ChefRegistration = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    nick_name: "",
    email: "",
    phone_number: "",
    password: "",
    profile_pic: "",
    company_name: "",
    last_4_ssn_ein: "",
    dl_state: "",
    dl_number: "",
    city: "",
    state: "",
    zip_code: "",
    address: "",
    jacket_style: "",
    jacket_type: "",
    jacket_size: "",
    jacket_custom_name: "",
    hat_style: "",
    sp_bags: "",
    apron: "",
  });

  const nextStep = () => {
    setStep((prevstep) => prevstep + 1);
  };

  const pevStep = () => {
    setStep((prevstep) => prevstep - 1);
  };

  // check what step is rendering
  const RenderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            nextStep={nextStep}
            pevStep={pevStep}
            formData={formData}
            step={step}
            setFormData={setFormData}
          />
        );

      case 2:
        return (
          <StepTwo
            nextStep={nextStep}
            pevStep={pevStep}
            formData={formData}
            step={step}
            setFormData={setFormData}
          />
        );

      case 3:
        return (
          <StepThree
            nextStep={nextStep}
            pevStep={pevStep}
            formData={formData}
            step={step}
            setFormData={setFormData}
          />
        );

      case 4:
        return (
          <StepFour
            nextStep={nextStep}
            pevStep={pevStep}
            formData={formData}
            step={step}
            setFormData={setFormData}
          />
        );

      default:
        return (
          <StepFive
            nextStep={nextStep}
            pevStep={pevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
    }
  };

  return (
    <>
      <Navbar />
      <div className="steps">
        <div className="container">
          <div className="validation_errors"></div>
        </div>

        <RenderStep />
      </div>
    </>
  );
};

export default ChefRegistration;
