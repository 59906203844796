import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";
import LeftArrowWhite from "../../assets/images/left-arrow-white.png";
import UploadPlus from "../../assets/images/logo/Icon awesome-plus.svg";
import RightArrowWhite from "../../assets/images/right-arrow-white.png";
import "./steptwo.scss";
import "./steptworesponsive.scss";

const StepTwo = (props) => {
  const { formData, setFormData: setData, nextStep, pevStep } = props;

  const [imageurl, setImageUrl] = useState("");
  const [file, uploadedFile] = useState("");
  const fileuplaod = useRef(null);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name field is required!"),
    last_name: Yup.string().required("LastName field is required!"),
    email: Yup.string()
      .email("Invaid Email")
      .required("Email field is required!"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone field is required!"),
    password: Yup.string()
      .min(8, "Password must atleast 8 character!")
      .required("Password field is required!"),
    confirm_password: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const handleSubmit = (values) => {
    setData(values);
    const formdata = new FormData();
    formdata.set("profile_pic", file);
    let profile_pic = formdata.get("profile_pic");

    setData({ ...values, profile_pic: profile_pic });
    nextStep();
  };

  const handleChange = useCallback(
    (e) => {
      const [files] = fileuplaod.current?.files;

      if (files) {
        let url = URL.createObjectURL(files);

        setImageUrl(url);
        localStorage.setItem("url", url);

        uploadedFile(files);
      }
    },
    [file]
  );

  return (
    <>
      <div id="stepper2" className="steppers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="step" id="stepper-two">
                <div className="header">
                  <div className="title">Step 02</div>
                  <div className="subtitle">Default Fields</div>
                </div>
                <div className="body">
                  <div className="form">
                    <Formik
                      initialValues={formData}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form id="default_fields">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>First Name</label>
                              <Field
                                name="first_name"
                                type="text"
                                className="form-control scnd-step"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="first_name" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Last Name</label>
                              <Field
                                name="last_name"
                                type="text"
                                className="form-control scnd-step"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="last_name" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>Nickname</label>
                          <Field
                            name="nick_name"
                            type="text"
                            className="form-control"
                            autoComplete="chrome-off"
                          />
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Phone</label>
                              <Field
                                name="phone_number"
                                type="text"
                                className="form-control scnd-step"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="phone_number" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Email</label>
                              <Field
                                name="email"
                                type="email"
                                className="form-control scnd-step"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="email" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Password</label>
                              <Field
                                name="password"
                                type="password"
                                className="form-control scnd-step"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="password" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <Field
                                name="confirm_password"
                                type="password"
                                className="form-control"
                                autoComplete="chrome-off"
                              />
                              <div className="error" style={{ color: "red" }}>
                                <ErrorMessage name="confirm_password" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="image-upload-section">
                              <div className="header">Profile Picture</div>
                              <div className="body">
                                <label className="src">
                                  <img
                                    id="primage_icon"
                                    src={UploadPlus}
                                    alt=""
                                  />
                                  <img
                                    style={
                                      imageurl || localStorage.getItem("url")
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                    id="primage_image"
                                    src={
                                      imageurl
                                        ? imageurl
                                        : localStorage.getItem("url")
                                    }
                                    alt=""
                                  />
                                  <input
                                    ref={fileuplaod}
                                    onInput={(e) => {
                                      handleChange(e);
                                    }}
                                    id="profileimage"
                                    type="file"
                                    style={{
                                      display: "none",
                                    }}
                                    name="profile_pic"
                                    accept="image/*"
                                  />
                                </label>

                                <div className="upload-img">Upload Image</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="continue">
                          <button
                            type="button"
                            onClick={pevStep}
                            className="accept-continue"
                          >
                            <img className="left-arrow" src={LeftArrowWhite} />
                            Back
                          </button>
                          <button type="submit" className="accept-continue">
                            Next
                            <img
                              className="right-arrow"
                              src={RightArrowWhite}
                            />
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
