import React from "react";
import "./stepfive.scss";
import "./stepfiveresponsive.scss";

const StepFive = () => {
  return (
    <>
      <div id="stepper5" className="steppers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="step" id="stepper-five">
                <div className="congress">
                  <div className="title">Congratulations</div>
                  <div className="subtitle">
                    Your registration Process Complete. Now, please wait for
                    background Confirmation.
                  </div>
                  <div class="login-button">
                    <a class="payment-link" href="">
                      Pay
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepFive;
