import { Routes, Route, Link } from "react-router-dom";
import "../assets/sass/_common.scss";
import Home from "../pages/Home/Home";
import ChefRegistration from "../pages/ChefRegistration/ChefRegistration";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chef-registration" element={<ChefRegistration />} />
      </Routes>
    </>
  );
}

export default App;
