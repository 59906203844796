import React from "react";
import Mainlogo from "../../assets/images/logo/main-logo.svg";
import "./navbar.scss";

const Navbar = () => {
  return (
    <>
      <header>
        <div className="nav-section">
          <nav className="navbar">
            <a href="">
              <img src={Mainlogo} alt="" />
            </a>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navbar;
