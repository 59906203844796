import React from "react";
import { Link } from "react-router-dom";
import IndexPageLeftImage from "../../assets/images/loginmain.png";
import Mainlogo from "../../assets/images/logo/main-logo.svg";
import "./home.scss";
import "./homeresponsive.scss";

const Home = () => {
  return (
    <>
      <section className="login-page">
        <div className="lg-page-body d-flex">
          <div className="left-sec">
            <div className="left-image">
              <img src={IndexPageLeftImage} alt="" />
            </div>
          </div>
          <div className="right-sec">
            <div className="form-section">
              <div className="main-form-sec">
                <div className="header">
                  <div className="header-img">
                    <img src={Mainlogo} alt="" />
                  </div>
                  <div className="title">Splendid Palate</div>
                  <div className="sub-title">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  </div>
                </div>
                <div className="body">
                  <div className="buttons">
                    <div>
                      <a className="lg-button log" href="">
                        LOG IN
                      </a>
                    </div>

                    <div>
                      <a className="lg-button reg" href="">
                        Customer Registration
                      </a>
                    </div>

                    <div>
                      <Link
                        className="lg-button chef-reg"
                        to="/chef-registration"
                      >
                        Chef Registration
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
