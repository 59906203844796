import React from "react";
import "./error.scss";
const Error = (props) =>{
    const {errorarray: errors} = props;
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="">
                        <ul>
                            {
                                errors.map((error , index) => (
                                    <li className="alert alert-danger" key={index}>{error}</li>
                                ))
                            }
                            
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Error;