import InsultedBags from "../../assets/images/dresses/2-insult-bags.png";
import FiveShoppingBags from "../../assets/images/dresses/5shopping-bag.png";
import BlackBib from "../../assets/images/dresses/black-bib.png";
import DoubleBrestedBlack from "../../assets/images/dresses/double-brested-b.png";
import DoubleBrested from "../../assets/images/dresses/double-brested.png";
import HightHatBlack from "../../assets/images/dresses/high-hat-black.png";
import HightHat from "../../assets/images/dresses/high-hat.png";
import ShortSleevBlack from "../../assets/images/dresses/short-sleev-b.png";
import ShortSleev from "../../assets/images/dresses/short-sleev.png";
import SingleBreastedBlack from "../../assets/images/dresses/single-brested-b.png";
import SingleBreasted from "../../assets/images/dresses/single-brested.png";
import SmallHeadWrapBlack from "../../assets/images/dresses/small-head-wrap-b.png";
import SmallHeadWrap from "../../assets/images/dresses/small-head-wrap.png";
import SmallToruqueBlack from "../../assets/images/dresses/small-tourque-b.png";
import SmallToruque from "../../assets/images/dresses/small-tourque.png";
import TenShoppingBags from "../../assets/images/dresses/ten-shopping-bag.png";
import WhiteBib from "../../assets/images/dresses/white-bib.png";

const jackets = [
  {
    id: 1,
    jacket_name: "Double Breasted",
    value: "double-breasted",
    jacket_image: DoubleBrested,
  },

  {
    id: 2,
    jacket_name: "Double Breasted Black",
    value: "double-breasted-b",
    jacket_image: DoubleBrestedBlack,
  },

  {
    id: 3,
    jacket_name: "Single Breasted",
    value: "one-sided",
    jacket_image: SingleBreasted,
  },

  {
    id: 4,
    jacket_name: "Single Breasted Black",
    value: "one-sided-b",
    jacket_image: SingleBreastedBlack,
  },

  {
    id: 5,
    jacket_name: "Short Sleeved",
    value: "short-sleeve",
    jacket_image: ShortSleev,
  },

  {
    id: 6,
    jacket_name: "Short Sleeved Black",
    value: "short-sleeve-b",
    jacket_image: ShortSleevBlack,
  },
];

const aprones = [
  {
    id: 1,
    aprone_name: "White apron",
    value: "white",
    aprone_image: WhiteBib,
  },
  {
    id: 2,
    aprone_name: "Black apron",
    value: "black",
    aprone_image: BlackBib,
  },
];

const hatstyles = [
  {
    id: 1,
    hat_name: "Small Head Wrap",
    value: "small-head-wrap",
    hat_image: SmallHeadWrap,
  },
  {
    id: 2,
    hat_name: "Small Head Wrap Black",
    value: "small-head-wrap-b",
    hat_image: SmallHeadWrapBlack,
  },

  {
    id: 3,
    hat_name: "High Hat",
    value: "high-hat",
    hat_image: HightHat,
  },

  {
    id: 4,
    hat_name: "High Hat Black",
    value: "high-hat-b",
    hat_image: HightHatBlack,
  },

  {
    id: 5,
    hat_name: "Small Torque",
    value: "small-tourque",
    hat_image: SmallToruque,
  },

  {
    id: 6,
    hat_name: "Small Torque Black",
    value: "small-tourque-b",
    hat_image: SmallToruqueBlack,
  },
];

const bags = [
  {
    id: 1,
    bag_name: "5 Shopping Bag + 1 Insulated Bag",
    value: "5 shopping bags 1 insulated bag",
    bag_image: FiveShoppingBags,
  },
  {
    id: 2,
    bag_name: "10 Shopping Bag",
    value: "10 shopping bags",
    bag_image: TenShoppingBags,
  },

  {
    id: 3,
    bag_name: "2 Insulated Bag",
    value: "2 insulated bags",
    bag_image: InsultedBags,
  },
];

export { jackets, hatstyles, aprones, bags };
