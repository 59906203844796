// importing all api endpoints here
import axios from "axios";
import { REGISTRATION_ENDPOINT } from "../api/endpoints";

// register services for chief registration
const cheifregistration = (data, nextStep, getError, setisSending) => {
  setisSending(1);

  let data_key = Object.keys(data);
  let data_value = Object.values(data);

  const formData = new FormData();

  for (let index = 0; index < data_key.length; index++) {
    formData.set(data_key[index], data_value[index]);
  }

  if (!formData.get("jacket_style")) {
    formData.delete("jacket_style");
  }

  if (!formData.get("jacket_type")) {
    formData.delete("jacket_type");
  }

  if (!formData.get("jacket_size")) {
    formData.delete("jacket_size");
  }

  if (!formData.get("jacket_custom_name")) {
    formData.delete("jacket_custom_name");
  }

  if (!formData.get("hat_style")) {
    formData.delete("hat_style");
  }

  if (!formData.get("apron")) {
    formData.delete("apron");
  }

  axios
    .post(REGISTRATION_ENDPOINT, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      const success = response.data.message;
      setisSending(0);

      nextStep();

      document.querySelector(".payment-link").href =
        response.data.data.payment_link;
    })
    .catch(function (error) {
      if (error.response.data.errors) {
        const errors = error.response.data.errors;
        setisSending(0);
        getError(errors);
      }
    });
};

// exporting the functions
export { cheifregistration };
