import { ErrorMessage, Field, Form, Formik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LeftArrowWhite from "../../assets/images/left-arrow-white.png";
import RightArrowWhite from "../../assets/images/right-arrow-white.png";
import { cheifregistration } from "../../services/CheifRegistrationService";
import Error from "../Errors/Error";
import "./stepfour.scss";
import { aprones, bags, hatstyles, jackets } from "./StepFourData";
import "./stepfourresponsive.scss";

const StepFour = (props) => {
  const { formData, setFormData: setData, nextStep, pevStep } = props;
  const [jacketstyles, setJaket] = useState([]);
  const [apronestyle, setAprone] = useState([]);
  const [hats, setHat] = useState([]);
  const [bagstyles, setBag] = useState([]);
  const [errors, getError] = useState([]);
  const [is_open_custom_input, setIsOpenCustomInput] = useState(false);
  const [is_sending, setisSending] = useState(0);

  useEffect(() => {
    setJaket(jackets);
  }, [jackets]);

  useEffect(() => {
    setAprone(aprones);
  }, [aprones]);

  useEffect(() => {
    setHat(hatstyles);
  }, [hatstyles]);

  useEffect(() => {
    setBag(bags);
  }, [bags]);

  const RenderError = (props) => {
    const errorarray = Object.values(props.errors);

    if (errorarray.length > 0) {
      return <Error errorarray={errorarray} />;
    } else {
      return <></>;
    }
  };

  const validationSchema = Yup.object({
    sp_bags: Yup.string().required("Please select a palate bag!"),
  });

  const handleSubmittwo = (values) => {
    cheifregistration(values, nextStep, getError, setisSending);
  };

  return (
    <>
      <div id="stepper4" className="steppers">
        <RenderError errors={errors} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="step" id="stepper-four">
                <div className="header">
                  <div className="title">Step 04</div>
                  <div className="subtitle">You'll receive once approved!!</div>
                </div>
                <div className="body">
                  <div className="form">
                    <Formik
                      initialValues={formData}
                      onSubmit={debounce(handleSubmittwo, 1000)}
                      validationSchema={validationSchema}
                    >
                      <Form id="recv_approve_form">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <label>Custom name on jacket :</label>
                            <div className="form-group">
                              <label>
                                <Field
                                  className="form-check-input cr-jacket"
                                  type="radio"
                                  name="jacket_custom_name"
                                  id="custom_name_jaket1"
                                  value="full_name"
                                />
                                <span>Full Name</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input cr-jacket"
                                  type="radio"
                                  name="jacket_custom_name"
                                  id="custom_name_jaket2"
                                  value="nick_name"
                                />
                                <span>Nick Name</span>
                              </label>

                              <label>
                                <Field
                                  className="form-check-input cr-jacket"
                                  type="radio"
                                  name="jacket_custom_name"
                                  id="custom_name_jaket3"
                                  value="custom_name_open"
                                  onClick={() => {
                                    setIsOpenCustomInput(!is_open_custom_input);
                                  }}
                                />
                                <span>Custom</span>
                              </label>
                              {is_open_custom_input ? (
                                <Field
                                  id="custominputopens"
                                  type="text"
                                  name="jacket_custom_name_input"
                                  className="form-control"
                                  placeholder="Other Name"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <label>Jacket Type:</label>
                            <div className="form-group">
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_type"
                                  id="jacket_type"
                                  value="man"
                                />
                                <span>Male</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_type"
                                  id="jacket_type1"
                                  value="women"
                                />
                                <span>Female</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-12">
                              <label>Size :</label>
                            </div>
                            <div className="col-sm-12">
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size1"
                                  value="XS"
                                />
                                <span>XS</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size2"
                                  value="S"
                                />
                                <span>S</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size3"
                                  value="M"
                                />
                                <span>M</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size4"
                                  value="L"
                                />
                                <span>L</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size5"
                                  value="XL"
                                />
                                <span>XL</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size6"
                                  value="XXL"
                                />
                                <span>XXL</span>
                              </label>
                              <label>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="jacket_size"
                                  id="size7"
                                  value="XXXL"
                                />
                                <span>XXXL</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Choose Jacket Style</label>
                          </div>
                          <div className="col-sm-12">
                            <div className="dress-choose d-flex">
                              {jacketstyles.map((jacket) => (
                                <label key={jacket.id} className="dress">
                                  <Field
                                    type="radio"
                                    value={jacket.value}
                                    name="jacket_style"
                                  />
                                  <div
                                    className={
                                      jacket.active
                                        ? "dress-pic aprone active"
                                        : "dress-pic aprone"
                                    }
                                  >
                                    <img src={jacket.jacket_image} alt="" />
                                  </div>
                                  <div className="text">
                                    {jacket.jacket_name}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Choose Apron :</label>
                          </div>
                          <div className="col-sm-12">
                            <div className="dress-choose d-flex">
                              {apronestyle.map((aprone) => (
                                <label key={aprone.id} className="dress">
                                  <Field
                                    type="radio"
                                    value={aprone.value}
                                    name="apron"
                                  />
                                  <div
                                    className={
                                      aprone.active
                                        ? "dress-pic aprone active"
                                        : "dress-pic aprone"
                                    }
                                  >
                                    <img src={aprone.aprone_image} alt="" />
                                  </div>
                                  <div className="text">
                                    {aprone.aprone_name}
                                  </div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Choose Hat Style :</label>
                          </div>
                          <div className="col-sm-12">
                            <div className="dress-choose d-flex">
                              {hats.map((hat) => (
                                <label key={hat.id} className="dress">
                                  <Field
                                    type="radio"
                                    value={hat.value}
                                    name="hat_style"
                                  />
                                  <div
                                    className={
                                      hat.active
                                        ? "dress-pic hat active"
                                        : "dress-pic hat"
                                    }
                                  >
                                    <img src={hat.hat_image} alt="" />
                                  </div>
                                  <div className="text">{hat.hat_name}</div>
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Splendid Palate Bags :</label>
                          </div>
                          <div className="col-sm-12">
                            <div className="dress-choose d-flex">
                              {bagstyles.map((bag) => (
                                <label className="dress" key={bag.id}>
                                  <Field
                                    type="radio"
                                    value={bag.value}
                                    name="sp_bags"
                                  />
                                  <div
                                    className={
                                      bag.active
                                        ? "dress-pic palate active"
                                        : "dress-pic palate"
                                    }
                                  >
                                    <img src={bag.bag_image} alt="" />
                                  </div>
                                  <div className="text">{bag.bag_name}</div>
                                </label>
                              ))}
                            </div>
                          </div>
                          <div className="error" style={{ color: "red" }}>
                            <ErrorMessage name="sp_bags" />
                          </div>
                        </div>
                        <div className="continue">
                          <button
                            type="button"
                            onClick={pevStep}
                            className="accept-continue"
                          >
                            <img src={LeftArrowWhite} className="left-arrow" />
                            Back
                          </button>
                          <button
                            type="submit"
                            className="accept-continue unique-accept"
                          >
                            {is_sending == 1
                              ? "Submitting.."
                              : "Application payment"}
                            <img
                              src={RightArrowWhite}
                              className="right-arrow"
                            />
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepFour;
