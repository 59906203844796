import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import RightArrowWhite from "../../assets/images/right-arrow-white.png";
import "./stepone.scss";
import "./steponeresponsive.scss";
const StepOne = (props) => {
  const { nextStep } = props;

  const initialValues = {
    checkone: "",
    checktwo: "",
    checkthree: "",
  };

  const validationSchema = Yup.object({
    checkone: Yup.string().required(),
    checktwo: Yup.string().required(),
    checkthree: Yup.string().required(),
  });
  const handleSubmit = (values) => {
    nextStep();
  };
  return (
    <>
      <div className="steppers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="step">
                <div className="header">
                  <div className="title">Step 01</div>
                  <div className="subtitle">Background Check Consent</div>
                </div>
                <div className="body">
                  <div className="content">
                    All chefs must pass a 3rd party background check in order to
                    be available for bookings on the Splendid Palate platform.
                    We encourage you to apply even if you have had a criminal
                    record. Integral Security Group, which is the company
                    performing the applicant verification, reviews a variety of
                    data to make an approval decision.
                  </div>

                  <div className="accepts-section">
                    <h6>Please select to proceed</h6>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <label className="accepts">
                          <Field type="radio" value="chkone" name="checkone" />
                          <div>
                            Completing this application requires a one-time
                            payment of $150.
                          </div>
                        </label>
                        <label className="accepts">
                          <Field type="radio" value="chktwo" name="checktwo" />
                          <div>
                            $100 is refunded if the applicant fails the
                            verification process.
                          </div>
                        </label>
                        <label className="accepts">
                          <Field
                            type="radio"
                            value="chkthree"
                            name="checkthree"
                          />
                          <div>
                            If successfully verified, the one-time fee of $150,
                            which covers the background check, Splendid Palate’s
                            administrative fees, and Splendid Palate’s starter
                            pack merchandise will be applied and is
                            non-refundable.
                          </div>
                        </label>

                        <div className="continue">
                          <button type="submit" className="accept-continue">
                            Next
                            <img
                              className="right-arrow"
                              src={RightArrowWhite}
                            />
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOne;
